<template>
  <div>
    <!--  Page Header  -->
    <div class="page-header d-flex justify-content-between align-items-center">
      <div class="d-flex align-items-center">
        <router-link :to="{ name: 'resource_center' }" >
          <button class="btn btn-control">
            <img alt="Return back" src="@/assets/icons/bold-icon previous.svg">
          </button>
        </router-link>
        <h3 class="page-header-title ms-2">Open House Flyer</h3>
        <span class="subtitle me-2">Editor</span>
      </div>
      <div class="d-flex align-items-center justify-content-end mt-3 mt-md-0 w-50">
         <div class="d-flex align-items-center me-2">
          <label for="themeField" class="me-2">Theme: </label>
          <multiselect v-model="editor.selectedTheme"
                      :options="themes"
                      :searchable="false"
                      :close-on-select="false"
                      :allow-empty="false"
                      style="max-width: 130px; min-width: 130px"
                      id="themeField"
                      :show-labels="false"
                      placeholder="Pick a value"></multiselect>
         </div>
        <base-button title="Save and Preview" action="secondary-default" @click-btn="saveFlyer" />
      </div>
    </div>

    <!--  Page Content  -->
    <div class="page-content p-normal">

      <!--   Page preloader   -->
      <div v-if="ui.loading" class="text-center mt-4">
        <div class="spinner-border" role="status">
          <span class="visually-hidden">Loading...</span>
        </div>
      </div>

      <div v-else>
        <!--   House details section   -->
        <section class="page-section mb-5">
          <div class="page-section-title">House Details</div>
          <div class="row">
            <div class="col-md-6">
              <div class="row h-100">
                <div class="col-9 h-100">
                  <div class="house-photo main-photo" @click="ui.mainUploaderPhoto = true">
                    <span v-if="images.main.length === 0">Upload House Photo</span>
                    <img v-else :src="images.main" alt="">
                    <img v-if="images.main.length > 0"
                         alt="" class="remove-photo" src="@/assets/icons/icon-close-black.svg"
                         @click="images.main = ''">
                  </div>
                </div>
                <div class="col-3">
                  <div class="house-photo optional-photo" @click="ui.firstOptional = true">
                    <span v-if="images.firstOptional.length === 0">Photo 1 (Optional)</span>
                    <img v-else :src="images.firstOptional" alt="">
                    <img v-if="images.firstOptional.length > 0"
                         alt="" class="remove-photo" src="@/assets/icons/icon-close-black.svg"
                         @click="images.firstOptional = ''">
                  </div>
                  <div class="house-photo optional-photo mt-2" @click="ui.secondOptional = true">
                    <span v-if="images.secondOptional.length === 0">Photo 2 (Optional)</span>
                    <img v-else :src="images.secondOptional" alt="">
                    <img v-if="images.secondOptional.length > 0"
                         alt="" class="remove-photo" src="@/assets/icons/icon-close-black.svg"
                         @click="images.secondOptional = ''">
                  </div>
                  <div class="house-photo optional-photo mt-2" @click="ui.thirdOptional = true">
                    <span v-if="images.thirdOptional.length === 0">Photo 3 (Optional)</span>
                    <img v-else :src="images.thirdOptional" alt="">
                    <img v-if="images.thirdOptional.length > 0"
                         alt="" class="remove-photo" src="@/assets/icons/icon-close-black.svg"
                         @click="images.thirdOptional = ''">
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group col-md-6">
                <label for="fieldPropertyPrice">Property Price</label>
                <div class="input-with-icon mb-2">
                  <span class="icon">$</span>
                  <input id="fieldPropertyPrice"
                         v-model="editor.houseDetails.property.price"
                         v-mask="mask"
                         class="form-control"
                         @keyup="changePrice">
                </div>
              </div>
              <div class="form-group full-width mt-3">
                <label for="fieldPropertyAddress">Property address</label>
                <textarea id="fieldPropertyAddress" v-model="editor.houseDetails.property.address" rows="2"
                          class="form-control" style="resize: none;"></textarea>
              </div>
              <div class="form-group full-width mt-3">
                <label for="fieldPropertyDesc">Property Description</label>
                <textarea id="fieldPropertyDesc" v-model="editor.houseDetails.property.desc" rows="2" :maxlength="maxInputLength" @input="handleInput"
                          class="form-control" style="resize: none;"></textarea>

                <p v-if="editor.houseDetails.property.desc.length >= maxInputLength" style="color: red;">Character limit reached</p>
              </div>
              <div class="row mt-3">
                <div class="col-md-3">
                  <div class="form-group">
                    <label>Total Bedrooms</label>
                    <div class="form-floating mb-3">
                      <input id="fieldTotalBed" v-model="editor.houseDetails.total.bedrooms" class="form-control"
                             min="0"
                             type="number">
                      <label for="fieldTotalBed">Beds</label>
                    </div>
                  </div>
                </div>
                <div class="col-md-3">
                  <div class="form-group">
                    <label>Total Baths</label>
                    <div class="form-floating mb-3">
                      <input id="fieldTotalBaths" v-model="editor.houseDetails.total.baths" class="form-control" min="0"
                             type="number">
                      <label for="fieldTotalBaths">Baths</label>
                    </div>
                  </div>
                </div>
                <div class="col-md-3">
                  <div class="form-group">
                    <label>Area Size</label>
                    <div class="form-floating mb-3">
                      <input id="fieldTotalArea" v-model="editor.houseDetails.total.size" class="form-control" min="0"
                             type="number">
                      <label for="fieldTotalArea">sqft</label>
                    </div>
                  </div>
                </div>
                <div class="col-md-3">
                  <div class="form-group">
                    <label>Lot Size</label>
                    <div class="form-floating mb-3">
                      <input id="fieldTotalLotSize" v-model="editor.houseDetails.total.lotSize" class="form-control"
                             min="0"
                             type="number">
                      <label for="fieldTotalLotSize">sqft</label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <!--   Loan Details section   -->
        <section class="page-section">
          <div class="page-section-title">Loan Details</div>
          <div class="page-section-table">
            <div class="page-section-table-body">
              <!--      Table Heading      -->
              <div class="page-section-table-row">
                <div class="page-section-table-cell"></div>
                <div class="page-section-table-cell table-head">Loan Option 1</div>
                <div class="page-section-table-cell table-head">Loan Option 2</div>
                <div class="page-section-table-cell table-head">Loan Option 3</div>
              </div>

              <!--      Loan Types      -->
              <div class="page-section-table-row">
                <div class="page-section-table-cell table-row-head table-responsive-tab">
                  <b>Loan Types</b>
                </div>
                <div class="page-section-table-cell">
                  <div class="form-group full-width">
                    <input v-model="loanDetails.loanOptionFirst.types" class="form-control"
                           placeholder="e.g. Conv 30yr Fixed" type="text">
                  </div>
                </div>
                <div class="page-section-table-cell">
                  <div class="form-group full-width">
                    <input v-model="loanDetails.loanOptionSecond.types" class="form-control"
                           placeholder="e.g. FHA 30yr Fixed" type="text">
                  </div>
                </div>
                <div class="page-section-table-cell">
                  <div class="form-group full-width">
                    <input v-model="loanDetails.loanOptionThird.types" class="form-control"
                           placeholder="e.g. VA 30yr Fixed" type="text">
                  </div>
                </div>
              </div>

              <!--      Rate      -->
              <div class="page-section-table-row">
                <div class="page-section-table-cell table-row-head">Interest Rate</div>
                <div class="page-section-table-cell">
                  <div class="form-group full-width">
                    <div class="input-with-icon">
                      <span class="icon">%</span>
                      <input v-model.number="loanDetails.loanOptionFirst.rate"
                             class="form-control" min="0" step="0.05" type="number">
                    </div>
                  </div>
                </div>
                <div class="page-section-table-cell">
                  <div class="form-group full-width">
                    <div class="input-with-icon">
                      <span class="icon">%</span>
                      <input v-model.number="loanDetails.loanOptionSecond.rate"
                             class="form-control" min="0" step="0.05" type="number">
                    </div>
                  </div>
                </div>
                <div class="page-section-table-cell">
                  <div class="form-group full-width">
                    <div class="input-with-icon">
                      <span class="icon">%</span>
                      <input v-model.number="loanDetails.loanOptionThird.rate"
                             class="form-control" step="0.05" type="number">
                    </div>
                  </div>
                </div>
              </div>

              <!--      APR      -->
              <div class="page-section-table-row">
                <div class="page-section-table-cell table-row-head">Annual Percentage Rate (APR)</div>
                <div class="page-section-table-cell">
                  <div class="form-group full-width">
                    <div class="input-with-icon">
                      <span class="icon">%</span>
                      <input v-model.number="loanDetails.loanOptionFirst.apr"
                             class="form-control" min="0" step="0.05" type="number">
                    </div>
                  </div>
                </div>
                <div class="page-section-table-cell">
                  <div class="form-group full-width">
                    <div class="input-with-icon">
                      <span class="icon">%</span>
                      <input v-model.number="loanDetails.loanOptionSecond.apr"
                             class="form-control" min="0" step="0.05" type="number">
                    </div>
                  </div>
                </div>
                <div class="page-section-table-cell">
                  <div class="form-group full-width">
                    <div class="input-with-icon">
                      <span class="icon">%</span>
                      <input v-model.number="loanDetails.loanOptionThird.apr"
                             class="form-control" min="0" step="0.05" type="number">
                    </div>
                  </div>
                </div>
              </div>

              <!--      Down Payment Amount      -->
              <div class="page-section-table-row">
                <div class="page-section-table-cell table-row-head">Down Payment Amount</div>
                <div class="page-section-table-cell">
                  <div class="form-group form-floating full-width">
                    <div class="input-with-icon">
                      <span class="icon">%</span>
                      <input v-model="loanDetails.loanOptionFirst.downPaymentAmount" class="form-control"
                             min="0" step="0.5" type="number"
                             @keyup="paymentСalculation('first')">
                      <label>= ${{ loanDetails.loanOptionFirst.downPaymentAmountDollars }}</label>
                    </div>
                  </div>
                </div>
                <div class="page-section-table-cell">
                  <div class="form-group form-floating full-width">
                    <div class="input-with-icon">
                      <span class="icon">%</span>
                      <input v-model="loanDetails.loanOptionSecond.downPaymentAmount" class="form-control" min="0"
                             step="0.5"
                             type="number"
                             @keyup="paymentСalculation('second')">
                      <label>= ${{ loanDetails.loanOptionSecond.downPaymentAmountDollars }}</label>
                    </div>
                  </div>
                </div>
                <div class="page-section-table-cell">
                  <div class="form-group form-floating full-width">
                    <div class="input-with-icon">
                      <span class="icon">
                        %
                      </span>
                      <input v-model="loanDetails.loanOptionThird.downPaymentAmount" class="form-control"
                             min="0"
                             step="0.5"
                             type="number"
                             @keyup="paymentСalculation('third')">
                      <label>= ${{ loanDetails.loanOptionThird.downPaymentAmountDollars }}</label>
                    </div>
                  </div>
                </div>
              </div>

              <!--      FHA UFMIP/VA Fee/USDA Fee      -->
              <div class="page-section-table-row">
                <div class="page-section-table-cell table-row-head">FHA UFMIP/VA Fee/USDA Fee</div>
                <div class="page-section-table-cell">
                  <div class="form-group full-width">
                    <div class="input-with-icon">
                      <span class="icon">%</span>
                      <input v-model.number="loanDetails.loanOptionFirst.fee"
                             class="form-control" min="0" step="0.05" type="number">
                    </div>
                  </div>
                </div>
                <div class="page-section-table-cell">
                  <div class="form-group full-width">
                    <div class="input-with-icon">
                      <span class="icon">%</span>
                      <input v-model.number="loanDetails.loanOptionSecond.fee"
                             class="form-control" min="0" step="0.05" type="number">
                    </div>
                  </div>
                </div>
                <div class="page-section-table-cell">
                  <div class="form-group full-width">
                    <div class="input-with-icon">
                      <span class="icon">%</span>
                      <input v-model.number="loanDetails.loanOptionThird.fee"
                             class="form-control" min="0" step="0.05" type="number">
                    </div>
                  </div>
                </div>
              </div>

              <!--      Loan Amount      -->
              <div class="page-section-table-row">
                <div class="page-section-table-cell table-row-head">Loan Amount</div>
                <div class="page-section-table-cell">
                  <div class="form-group full-width">
                    <div class="input-with-icon">
                      <span class="icon">$</span>
                      <input v-model="loanDetails.loanOptionFirst.loanAmount"
                             v-mask="mask" class="form-control">
                    </div>
                  </div>
                </div>
                <div class="page-section-table-cell">
                  <div class="form-group full-width">
                    <div class="input-with-icon">
                      <span class="icon">
                        $
                      </span>
                      <input v-model="loanDetails.loanOptionSecond.loanAmount" v-mask="mask"
                             class="form-control" min="0">
                    </div>
                  </div>
                </div>
                <div class="page-section-table-cell">
                  <div class="form-group full-width">
                    <div class="input-with-icon">
                      <span class="icon">
                        $
                      </span>
                      <input v-model="loanDetails.loanOptionThird.loanAmount" v-mask="mask"
                             class="form-control" min="0">
                    </div>
                  </div>
                </div>
              </div>

              <!--      Loan Terms      -->
              <div class="page-section-table-row">
                <div class="page-section-table-cell table-row-head">Loan Term (Years)</div>
                <div class="page-section-table-cell">
                  <div class="form-group full-width">
                    <div class="form-floating full-width">
                      <input v-model="loanDetails.loanOptionFirst.loanTerms" class="form-control" max="45" min="0"
                             type="number">
                      <label>years</label>
                    </div>
                  </div>
                </div>
                <div class="page-section-table-cell">
                  <div class="form-group full-width">
                    <div class="form-floating full-width">
                      <input v-model="loanDetails.loanOptionSecond.loanTerms" class="form-control" max="45" min="0"
                             type="number">
                      <label>years</label>
                    </div>
                  </div>
                </div>
                <div class="page-section-table-cell">
                  <div class="form-group full-width">
                    <div class="form-floating full-width">
                      <input v-model="loanDetails.loanOptionThird.loanTerms" class="form-control" max="45" min="0"
                             type="number">
                      <label>years</label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="page-section-table">
            <div class="page-section-table-body">
              <!--      ESTIMATED MONTHLY PAYMENT INFO      -->
              <div class="page-section-table-row">
                <div class="page-section-table-cell table-row-head table-responsive-tab w-100">
                  <b>ESTIMATED MONTHLY PAYMENT INFO</b>
                </div>
              </div>
            </div>
          </div>

          <div class="page-section-table">
            <div class="page-section-table-body">
              <!--      Principal & Interest (P&I)      -->
              <div class="page-section-table-row">
                <div class="page-section-table-cell table-row-head">Principal & Interest (P&I)</div>
                <div class="page-section-table-cell">
                  <div class="form-group full-width">
                    <div class="input-with-icon">
                      <span class="icon">$</span>
                      <input v-model="loanDetails.loanOptionFirst.principal"
                             v-mask="mask" class="form-control" min="0">
                    </div>
                  </div>
                </div>
                <div class="page-section-table-cell">
                  <div class="form-group full-width">
                    <div class="input-with-icon">
                      <span class="icon">$</span>
                      <input v-model="loanDetails.loanOptionSecond.principal"
                             v-mask="mask" class="form-control">
                    </div>
                  </div>
                </div>
                <div class="page-section-table-cell">
                  <div class="form-group full-width">
                    <div class="input-with-icon">
                      <span class="icon">$</span>
                      <input v-model="loanDetails.loanOptionThird.principal"
                             v-mask="mask" class="form-control">
                    </div>
                  </div>
                </div>
              </div>

              <!--      Mortgage Insurance/USDA Fee      -->
              <div class="page-section-table-row">
                <div class="page-section-table-cell table-row-head">Mortgage Insurance/USDA Fee</div>
                <div class="page-section-table-cell">
                  <div class="form-group full-width">
                    <div class="input-with-icon">
                      <span class="icon">$</span>
                      <input v-model="loanDetails.loanOptionFirst.mortgageFee"
                             v-mask="mask" class="form-control">
                    </div>
                  </div>
                </div>
                <div class="page-section-table-cell">
                  <div class="form-group full-width">
                    <div class="input-with-icon">
                      <span class="icon">$</span>
                      <input v-model="loanDetails.loanOptionSecond.mortgageFee"
                             v-mask="mask" class="form-control">
                    </div>
                  </div>
                </div>
                <div class="page-section-table-cell">
                  <div class="form-group full-width">
                    <div class="input-with-icon">
                      <span class="icon">$</span>
                      <input v-model="loanDetails.loanOptionThird.mortgageFee"
                             v-mask="mask" class="form-control">
                    </div>
                  </div>
                </div>
              </div>

              <!--      Taxes + Insurance + HOA Dues      -->
              <div class="page-section-table-row">
                <div class="page-section-table-cell table-row-head">Taxes + Insurance + HOA Dues</div>
                <div class="page-section-table-cell">
                  <div class="form-group full-width">
                    <div class="input-with-icon">
                      <span class="icon">$</span>
                      <input v-model="loanDetails.loanOptionFirst.taxesInsuranceDues"
                             v-mask="mask" class="form-control">
                    </div>
                  </div>
                </div>
                <div class="page-section-table-cell">
                  <div class="form-group full-width">
                    <div class="input-with-icon">
                      <span class="icon">$</span>
                      <input v-model="loanDetails.loanOptionSecond.taxesInsuranceDues"
                             v-mask="mask" class="form-control">
                    </div>
                  </div>
                </div>
                <div class="page-section-table-cell">
                  <div class="form-group full-width">
                    <div class="input-with-icon">
                      <span class="icon">$</span>
                      <input v-model="loanDetails.loanOptionThird.taxesInsuranceDues"
                             v-mask="mask" class="form-control">
                    </div>
                  </div>
                </div>
              </div>

              <!--      Total Monthly Payment      -->
              <div class="page-section-table-row">
                <div class="page-section-table-cell table-row-head">Total Monthly Payment</div>
                <div class="page-section-table-cell">
                  <div class="form-group full-width">
                    <div class="input-with-icon">
                      <span class="icon">$</span>
                      <input v-model="loanDetails.loanOptionFirst.totalMonthlyPayment"
                             v-mask="mask" class="form-control">
                    </div>
                  </div>
                </div>
                <div class="page-section-table-cell">
                  <div class="form-group full-width">
                    <div class="input-with-icon">
                      <span class="icon">$</span>
                      <input v-model="loanDetails.loanOptionSecond.totalMonthlyPayment"
                             v-mask="mask" class="form-control">
                    </div>
                  </div>
                </div>
                <div class="page-section-table-cell">
                  <div class="form-group full-width">
                    <div class="input-with-icon">
                      <span class="icon">$</span>
                      <input v-model="loanDetails.loanOptionThird.totalMonthlyPayment"
                             v-mask="mask" class="form-control">
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="page-section-table">
            <div class="page-section-table-body">
              <!--      ESTIMATED FEES / COSTS / DISCOUNT    -->
              <div class="page-section-table-row">
                <div class="page-section-table-cell table-row-head table-responsive-tab w-100">
                  <b>ESTIMATED FEES / COSTS / DISCOUNT</b>
                </div>
              </div>
            </div>
          </div>

          <div class="page-section-table">
            <div class="page-section-table-body">
              <!--      Points | Origination Fee      -->
              <div class="page-section-table-row">
                <div class="page-section-table-cell table-row-head">Points | Origination Fee</div>
                <div class="page-section-table-cell">
                  <div class="d-flex double-input">
                    <div class="form-group full-width">
                      <div class="input-with-icon">
                        <span class="icon">$</span>
                        <input v-model="loanDetails.loanOptionFirst.points"
                               v-mask="mask" class="form-control">
                      </div>
                    </div>
                    <div class="form-group full-width">
                      <div class="input-with-icon">
                        <span class="icon">$</span>
                        <input v-model="loanDetails.loanOptionFirst.originationFee"
                               v-mask="mask" class="form-control">
                      </div>
                    </div>
                  </div>
                </div>
                <div class="page-section-table-cell">
                  <div class="d-flex double-input">
                    <div class="form-group full-width">
                      <div class="input-with-icon">
                        <span class="icon">$</span>
                        <input v-model="loanDetails.loanOptionSecond.points"
                               v-mask="mask" class="form-control">
                      </div>
                    </div>
                    <div class="form-group full-width">
                      <div class="input-with-icon">
                        <span class="icon">$</span>
                        <input v-model="loanDetails.loanOptionSecond.originationFee"
                               v-mask="mask" class="form-control">
                      </div>
                    </div>
                  </div>
                </div>
                <div class="page-section-table-cell">
                  <div class="d-flex double-input">
                    <div class="form-group full-width">
                      <div class="input-with-icon">
                        <span class="icon">$</span>
                        <input v-model="loanDetails.loanOptionThird.points"
                               v-mask="mask" class="form-control">
                      </div>
                    </div>
                    <div class="form-group full-width">
                      <div class="input-with-icon">
                        <span class="icon">$</span>
                        <input v-model="loanDetails.loanOptionThird.originationFee"
                               v-mask="mask" class="form-control">
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <!--      Closing Costs + Settlement Fees      -->
              <div class="page-section-table-row">
                <div class="page-section-table-cell table-row-head">
                  Closing Costs + Settlement Fees
                </div>
                <div class="page-section-table-cell">
                  <div class="form-group full-width">
                    <div class="input-with-icon">
                      <span class="icon">$</span>
                      <input v-model="loanDetails.loanOptionFirst.closingCostsSettlementFees"
                             v-mask="mask" class="form-control">
                    </div>
                  </div>
                </div>
                <div class="page-section-table-cell">
                  <div class="form-group full-width">
                    <div class="input-with-icon">
                      <span class="icon">$</span>
                      <input v-model="loanDetails.loanOptionSecond.closingCostsSettlementFees"
                             v-mask="mask" class="form-control">
                    </div>
                  </div>
                </div>
                <div class="page-section-table-cell">
                  <div class="form-group full-width">
                    <div class="input-with-icon">
                      <span class="icon">$</span>
                      <input v-mask="mask"
                             v-model="loanDetails.loanOptionThird.closingCostsSettlementFees"
                             class="form-control">
                    </div>
                  </div>
                </div>
              </div>

              <!--      Prepaid Interest      -->
              <div class="page-section-table-row">
                <div class="page-section-table-cell table-row-head">Prepaid Interest</div>
                <div class="page-section-table-cell">
                  <div class="d-flex double-input">
                    <div class="form-group full-width">
                      <div class="form-floating full-width">
                        <input v-model.number="loanDetails.loanOptionFirst.prepaidInterest.days"
                               class="form-control" min="0" type="number">
                        <label for="fieldTotalLotSize">days</label>
                      </div>
                    </div>
                    <div class="form-group full-width">
                      <div class="input-with-icon">
                        <span class="icon">$</span>
                        <input v-model="loanDetails.loanOptionFirst.prepaidInterest.amount"
                               v-mask="mask" class="form-control">
                      </div>
                    </div>
                  </div>
                </div>
                <div class="page-section-table-cell">
                  <div class="d-flex double-input">
                    <div class="form-group full-width">
                      <div class="form-floating full-width">
                        <input v-model.number="loanDetails.loanOptionSecond.prepaidInterest.days"
                               class="form-control" min="0" type="number">
                        <label for="fieldTotalLotSize">days</label>
                      </div>
                    </div>
                    <div class="form-group full-width">
                      <div class="input-with-icon">
                        <span class="icon">$</span>
                        <input v-model="loanDetails.loanOptionSecond.prepaidInterest.amount"
                               v-mask="mask" class="form-control">
                      </div>
                    </div>
                  </div>
                </div>
                <div class="page-section-table-cell">
                  <div class="d-flex double-input">
                    <div class="form-group full-width">
                      <div class="form-floating full-width">
                        <input v-model.number="loanDetails.loanOptionThird.prepaidInterest.days"
                               class="form-control" min="0" type="number">
                        <label for="fieldTotalLotSize">days</label>
                      </div>
                    </div>
                    <div class="form-group full-width">
                      <div class="input-with-icon">
                        <span class="icon">$</span>
                        <input v-model="loanDetails.loanOptionThird.prepaidInterest.amount"
                               v-mask="mask" class="form-control">
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <!--      Other Prepaids (Taxes + Insurance)      -->
              <div class="page-section-table-row">
                <div class="page-section-table-cell table-row-head">Other Prepaids (Taxes + Insurance)</div>
                <div class="page-section-table-cell">
                  <div class="form-group full-width">
                    <div class="input-with-icon">
                      <span class="icon">$</span>
                      <input v-model="loanDetails.loanOptionFirst.otherPrepaids"
                             v-mask="mask" class="form-control">
                    </div>
                  </div>
                </div>
                <div class="page-section-table-cell">
                  <div class="form-group full-width">
                    <div class="input-with-icon">
                      <span class="icon">$</span>
                      <input v-model="loanDetails.loanOptionSecond.otherPrepaids"
                             v-mask="mask" class="form-control">
                    </div>
                  </div>
                </div>
                <div class="page-section-table-cell">
                  <div class="form-group full-width">
                    <div class="input-with-icon">
                      <span class="icon">$</span>
                      <input v-model="loanDetails.loanOptionThird.otherPrepaids"
                             v-mask="mask" class="form-control">
                    </div>
                  </div>
                </div>
              </div>

              <!--      Seller Concession / Discount / Rebate      -->
              <div class="page-section-table-row">
                <div class="page-section-table-cell table-row-head">Seller Concession / Discount / Rebate</div>
                <div class="page-section-table-cell">
                  <div class="form-group full-width">
                    <div class="input-with-icon">
                      <span class="icon">$</span>
                      <input v-model="loanDetails.loanOptionFirst.sellerConcession"
                             v-mask="mask" class="form-control">
                    </div>
                  </div>
                </div>
                <div class="page-section-table-cell">
                  <div class="form-group full-width">
                    <div class="input-with-icon">
                      <span class="icon">$</span>
                      <input v-model="loanDetails.loanOptionSecond.sellerConcession"
                             v-mask="mask" class="form-control">
                    </div>
                  </div>
                </div>
                <div class="page-section-table-cell">
                  <div class="form-group full-width">
                    <div class="input-with-icon">
                      <span class="icon">$</span>
                      <input v-model="loanDetails.loanOptionThird.sellerConcession"
                             v-mask="mask" class="form-control">
                    </div>
                  </div>
                </div>
              </div>

              <!--      Estimated Funds Due at Closing      -->
              <div class="page-section-table-row">
                <div class="page-section-table-cell table-row-head">Estimated Funds Due at Closing</div>
                <div class="page-section-table-cell">
                  <div class="form-group full-width">
                    <div class="input-with-icon">
                      <span class="icon">$</span>
                      <input v-model="loanDetails.loanOptionFirst.estimatedFunds"
                             v-mask="mask" class="form-control">
                    </div>
                  </div>
                </div>
                <div class="page-section-table-cell">
                  <div class="form-group full-width">
                    <div class="input-with-icon">
                      <span class="icon">$</span>
                      <input v-model="loanDetails.loanOptionSecond.estimatedFunds"
                             v-mask="mask" class="form-control">
                    </div>
                  </div>
                </div>
                <div class="page-section-table-cell">
                  <div class="form-group full-width">
                    <div class="input-with-icon">
                      <span class="icon">$</span>
                      <input v-mask="mask"
                             v-model="loanDetails.loanOptionThird.estimatedFunds" class="form-control">
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <!--   Loan Officer & Realtor info section   -->
        <section class="page-section mt-3">
          <div class="row">
            <div class="col-md-6">
              <div class="page-section-title">Loan Officer</div>
              <div class="info-block">
                <div class="row">
                  <div class="col-md-3">
                    <div class="info-block-avatar" @click="ui.officerPhoto = true">
                      <span v-if="images.officerPhoto.length === 0 && Object.keys(selectedAgent).length === 0">
                        Add Photo
                      </span>
                      <img v-if="images.officerPhoto.length > 0" :src="images.officerPhoto" alt="">
                      <img v-if="images.officerPhoto.length > 0"
                           alt="" class="remove-photo" src="@/assets/icons/icon-close-black.svg"
                           @click="images.officerPhoto = ''">
                      <img v-if="Object.keys(selectedAgent).length > 0 && images.officerPhoto.length === 0"
                           :src="agentPhoto(selectedAgent.photo)"
                           alt="">
                    </div>
                  </div>
                  <div class="col-md-9">
                    <div class="row">
                      <div class="col-md-6  mt-3 mt-md-0">
                        <multiselect v-model="selectedAgent"
                                     :allow-empty="false"
                                     :close-on-select="true"
                                     :options="agents"
                                     :searchable="true"
                                     :show-labels="false"
                                     class="full-width"
                                     label="fullName"
                                     placeholder="Select Agent">
                        </multiselect>
                      </div>
                      <div class="col-md-6">
                        <div class="form-group full-width  mt-3 mt-md-0">
                          <input v-model="selectedAgent.position" class="form-control" placeholder="e.g. Loan Officer"
                                 type="text">
                        </div>
                      </div>
                      <div class="col-md-6 mt-3">
                        <div class="form-group full-width">
                          <input v-model="selectedAgent.nmls" class="form-control" placeholder="NMLS optional"
                                 type="text">
                        </div>
                      </div>
                      <div class="col-md-6 mt-3">
                        <div class="form-group full-width">
                          <input v-model="selectedAgent.phone" v-mask="'(###) ###-####'" class="form-control"
                                 placeholder="Phone"
                                 type="tel">
                        </div>
                      </div>
                      <div class="col-12 mt-4">
                        <div class="info-block-logo">
                          <img alt="" src="@/assets/images/logo-clearmortgagecapital-big.svg">
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-6">
              <div class="page-section-title">
                Realtor
              </div>
              <div class="info-block">
                <div class="row">
                  <div class="col-md-3">
                    <div class="info-block-avatar" @click="ui.realtorPhoto = true">
                      <span v-if="images.realtorPhoto.length === 0">Add Photo</span>
                      <img v-else :src="images.realtorPhoto" alt="">
                    </div>
                  </div>
                  <div class="col-md-9">
                    <div class="row">
                      <div class="col-md-6">
                        <div class="form-group full-width mt-3 mt-md-0">
                          <input v-model="realtor.fullname" class="form-control" placeholder="Realtor Name" type="text">
                        </div>
                      </div>
                      <div class="col-md-6 mt-3 mt-md-0">
                        <div class="form-group full-width">
                          <input v-model="realtor.position" class="form-control" placeholder="e.g. Loan Officer"
                                 type="text">
                        </div>
                      </div>
                      <div class="col-md-6 mt-3">
                        <div class="form-group full-width">
                          <input v-model="realtor.nmls" class="form-control" placeholder="DRE# optional" type="text">
                        </div>
                      </div>
                      <div class="col-md-6 mt-3">
                        <div class="form-group full-width">
                          <input v-model="realtor.phone" v-mask="'(###) ###-####'" class="form-control"
                                 placeholder="Phone"
                                 type="tel">
                        </div>
                      </div>
                      <div class="col-12 mt-4">
                        <div class="info-block-logo" @click="ui.realtorCompanyLogo = true">
                          <span v-if="images.realtorCompanyLogo.length === 0">Upload logo</span>
                          <img :src="images.realtorCompanyLogo" alt="">
                          <img v-if="images.realtorCompanyLogo.length > 0"
                               alt="" src="@/assets/icons/icon-close.svg"
                               @click="images.realtorCompanyLogo = ''">
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section class="page-section mt-3 mb-5">
          <div class="page-section-title mt-4">Disclaimer</div>
          <div v-if="!ui.showEditDisclaimer">
            <p class="mb-0">
              {{ disclaimer }}
            </p>
            <div class="d-flex">
              <button class="btn btn-outline-dark mt-3" @click="ui.showEditDisclaimer = true">
                Edit Disclaimer
              </button>
              <button class="btn btn-outline-dark mt-3 ms-3" @click="disclaimer = defaultDisclaimer" v-if="!isDefaultDisclaimer">
                Set Default Disclaimer
              </button>
            </div>

          </div>
          <div v-else>
            <div class="form-group full-width d-100">
              <textarea v-model="disclaimer" name="disclaimerField" id="disclaimerField" cols="30" rows="3"></textarea>
              <div class="d-flex">
                <button class="btn bg-green mt-3" @click="ui.showEditDisclaimer = false">Save Disclaimer</button>
                <button class="btn btn-outline-dark mt-3 ms-3" @click="disclaimer = defaultDisclaimer" v-if="!isDefaultDisclaimer">
                  Set Default Disclaimer
                </button>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>

    <!--  Main House Photo  -->
    <my-upload v-model="ui.mainUploaderPhoto"
               :height="340"
               :noCircle="true"
               :params="params"
               :width="450"
               :url="backendUrl(`/api/v1/open-house-flyer/upload/resources/main/${$store.getters.getUserId}`)"
               field="img"
               img-format="jpg"
               lang-type="en"
               @crop-success="cropSuccess"
               @crop-upload-success="cropUploadSuccess"/>

    <!--  First Optional House Photo  -->
    <my-upload v-model="ui.firstOptional"
               :height="148"
               :noCircle="true"
               :params="params"
               :width="148"
               :url="backendUrl(`/api/v1/open-house-flyer/upload/resources/firstOptional/${$store.getters.getUserId}`)"
               field="img"
               img-format="jpg"
               lang-type="en"
               @crop-success="cropSuccess"
               @crop-upload-success="cropUploadSuccess"/>

    <!--  Second Optional House Photo  -->
    <my-upload v-model="ui.secondOptional"
               :height="102"
               :noCircle="true"
               :params="params"
               :width="102"
               :url="backendUrl(`/api/v1/open-house-flyer/upload/resources/secondOptional/${$store.getters.getUserId}`)"
               field="img"
               img-format="jpg"
               lang-type="en"
               @crop-success="cropSuccess"
               @crop-upload-success="cropUploadSuccess"/>

    <!--  Third Optional House Photo  -->
    <my-upload v-model="ui.thirdOptional"
               :height="148"
               :noCircle="true"
               :params="params"
               :width="148"
               :url="backendUrl(`/api/v1/open-house-flyer/upload/resources/thirdOptional/${$store.getters.getUserId}`)"
               field="img"
               img-format="jpg"
               lang-type="en"
               @crop-success="cropSuccess"
               @crop-upload-success="cropUploadSuccess"/>

    <!--  Officer Custom Photo  -->
    <circle-cropper v-if="ui.officerPhoto"
      :url="backendUrl(`/api/v1/open-house-flyer/upload/resources/officerPhoto/${$store.getters.getUserId}`)"
      :field="'img'"
      @crop-success="cropSuccess"
      @crop-upload-success="cropUploadSuccess"
    />

    <!--  Realtor Photo  -->
    <circle-cropper v-if="ui.realtorPhoto"
      :url="backendUrl(`/api/v1/open-house-flyer/upload/resources/realtorPhoto/${$store.getters.getUserId}`)"
      :field="'img'"
      @crop-success="cropSuccess"
      @crop-upload-success="cropUploadSuccess"
    />

    <!--  Realtor Company Photo  -->
    <my-upload v-model="ui.realtorCompanyLogo"
               :height="30"
               :noCircle="true"
               :noSquare="true"
               :params="params"
               :width="150"
               :url="backendUrl(`/api/v1/open-house-flyer/upload/resources/realtorCompanyLogo/${$store.getters.getUserId}`)"
               field="img"
               img-format="jpg"
               lang-type="en"
               @crop-success="cropSuccess"
               @crop-upload-success="cropUploadSuccess"/>
  </div>
</template>

<script>
import Multiselect from 'vue-multiselect'
import myUpload from 'vue-image-crop-upload'
import CircleCropper from '@/components/modal/CircleCropper'
import createNumberMask from 'text-mask-addons/dist/createNumberMask';
import BaseButton from '../../components/BaseButton.vue';

const currencyMask = createNumberMask({
  prefix: '',
  allowDecimal: true,
  includeThousandsSeparator: true,
  allowNegative: false,
});

export default {
  name: 'OpenHouseFlyerEditor',
  components: {Multiselect, myUpload, CircleCropper, BaseButton},
  data() {
    return {
      themes: [ 'White', 'Blue' ],
      editor: {
        selectedTheme: 'White',
        houseDetails: {
          property: {
            price: 0,
            address: '',
            desc: ''
          },
          total: {
            bedrooms: 0,
            baths: 0,
            size: 0,
            lotSize: 0
          }
        }
      },
      loanDetails: {
        loanOptionFirst: {
          types: 'Conv 30yr Fixed',
          rate: 0,
          apr: 0,
          downPaymentAmount: 0,
          downPaymentAmountDollars: 0,
          fee: 0,
          loanAmount: 0,
          loanTerms: 0,
          principal: 0,
          mortgageFee: 0,
          taxesInsuranceDues: 0,
          totalMonthlyPayment: 0,
          points: 0,
          originationFee: 0,
          closingCostsSettlementFees: 0,
          prepaidInterest: {
            days: 0,
            amount: 0
          },
          otherPrepaids: 0,
          sellerConcession: 0,
          estimatedFunds: 0,
        },
        loanOptionSecond: {
          types: 'Conv 30yr Fixed',
          rate: 0,
          apr: 0,
          downPaymentAmount: 0,
          downPaymentAmountDollars: 0,
          fee: 0,
          loanAmount: 0,
          loanTerms: 0,
          principal: 0,
          mortgageFee: 0,
          taxesInsuranceDues: 0,
          totalMonthlyPayment: 0,
          points: 0,
          originationFee: 0,
          closingCostsSettlementFees: 0,
          prepaidInterest: {
            days: 0,
            amount: 0
          },
          otherPrepaids: 0,
          sellerConcession: 0,
          estimatedFunds: 0,
        },
        loanOptionThird: {
          types: 'Conv 30yr Fixed',
          rate: 0,
          apr: 0,
          downPaymentAmount: 0,
          downPaymentAmountDollars: 0,
          fee: 0,
          loanAmount: 0,
          loanTerms: 0,
          principal: 0,
          mortgageFee: 0,
          taxesInsuranceDues: 0,
          totalMonthlyPayment: 0,
          points: 0,
          originationFee: 0,
          closingCostsSettlementFees: 0,
          prepaidInterest: {
            days: 0,
            amount: 0
          },
          otherPrepaids: 0,
          sellerConcession: 0,
          estimatedFunds: 0,
        }
      },
      selectedAgent: {},
      images: {
        main: '',
        firstOptional: '',
        secondOptional: '',
        thirdOptional: '',
        officerPhoto: '',
        realtorPhoto: '',
        realtorCompanyLogo: ''
      },
      realtor: {
        fullname: '',
        nmls: '',
        phone: '',
        position: ''
      },
      ui: {
        loading: false,
        mainUploaderPhoto: false,
        firstOptional: false,
        secondOptional: false,
        thirdOptional: false,
        officerPhoto: false,
        realtorPhoto: false,
        realtorCompanyLogo: false,
        showEditDisclaimer: false
      },
      agents: [],
      mask: currencyMask,
      params: {
        token: '123456798',
        name: 'avatar'
      },
      savedData: null,
      disclaimer: `Disclosed rate assumes a $400,000 purchase loan on an owner-occupied single family residence in California. Minimum 740 FICO credit score, 50% loan to value with no subordinate debt. If these factors are not met, you may still qualify and additional pricing adjustments may apply. Subject to underwriting approval. Not all applicants will qualify. Equal Housing Lender. Loans made or arranged pursuant to a California Department of Real Estate License #02110465. NMLS 1935005 Clear Mortgage Capital, Inc.`,
      defaultDisclaimer: `Disclosed rate assumes a $400,000 purchase loan on an owner-occupied single family residence in California. Minimum 740 FICO credit score, 50% loan to value with no subordinate debt. If these factors are not met, you may still qualify and additional pricing adjustments may apply. Subject to underwriting approval. Not all applicants will qualify. Equal Housing Lender. Loans made or arranged pursuant to a California Department of Real Estate License #02110465. NMLS 1935005 Clear Mortgage Capital, Inc.`,
      maxInputLength: 270
    }
  },
  methods: {
    getData() {
      this.ui.loading = true

      // Get Agents
      this.$http.get(`api/v1/form/wizard-agents`)
        .then(res => {
          this.agents = res.data
          if (Object.keys(this.selectedAgent).length === 0) {
            this.selectedAgent = res.data[0]
          }
          this.ui.loading = false
        })
        .catch()

      // Get Saved data
      let flyerData = this.$store.getters.getFlyerData;
      if (flyerData !== false) {
        this.editor = flyerData.editor
        this.loanDetails = flyerData.loanDetails
        this.selectedAgent = flyerData.selectedAgent
        this.images = flyerData.images
        this.realtor = flyerData.realtor
        this.disclaimer = flyerData.disclaimer !== null ? flyerData.disclaimer : this.disclaimer
      }
    },
    cropSuccess(imgDataUrl) {
      if (this.ui.mainUploaderPhoto === true) {
        this.images.main = imgDataUrl
      } else if (this.ui.firstOptional === true) {
        this.images.firstOptional = imgDataUrl
      } else if (this.ui.secondOptional === true) {
        this.images.secondOptional = imgDataUrl
      } else if (this.ui.thirdOptional === true) {
        this.images.thirdOptional = imgDataUrl
      } else if (this.ui.officerPhoto === true) {
        this.images.officerPhoto = imgDataUrl
      } else if (this.ui.realtorPhoto === true) {
        this.images.realtorPhoto = imgDataUrl
      } else if (this.ui.realtorCompanyLogo === true) {
        this.images.realtorCompanyLogo = imgDataUrl
      }
    },
    cropUploadSuccess() {
      if (this.ui.mainUploaderPhoto === true) {
        this.ui.mainUploaderPhoto = false
      } else if (this.ui.firstOptional === true) {
        this.ui.firstOptional = false
      } else if (this.ui.secondOptional === true) {
        this.ui.secondOptional = false
      } else if (this.ui.thirdOptional === true) {
        this.ui.thirdOptional = false
      } else if (this.ui.officerPhoto === true) {
        this.ui.officerPhoto = false
      } else if (this.ui.realtorPhoto === true) {
        this.ui.realtorPhoto = false
      } else if (this.ui.realtorCompanyLogo === true) {
        this.ui.realtorCompanyLogo = false
      }
    },
    saveFlyer() {
      if (this.disclaimer !== this.defaultDisclaimer) {
        alert('You have modified the disclaimer and it needs to be reviewed by compliance. Please print to PDF and submit to Compliance Review in Vision.')
      }
      this.$store.commit('saveFlyer', {
        editor: this.editor,
        loanDetails: this.loanDetails,
        selectedAgent: this.selectedAgent,
        images: this.images,
        realtor: this.realtor,
        disclaimer: this.disclaimer
      });

      this.$router.push({name: 'house_flyer_preview'})
    },
    paymentСalculation(val) {
      let housePrice = this.editor.houseDetails.property.price;
      housePrice = housePrice.replaceAll(',', '').replaceAll('.00', '').replaceAll('.', '')
      housePrice = parseInt(housePrice)

      if (val === 'first') {
        this.loanDetails.loanOptionFirst.downPaymentAmountDollars = (housePrice * this.loanDetails.loanOptionFirst.downPaymentAmount) / 100
      } else if (val === 'second') {
        this.loanDetails.loanOptionSecond.downPaymentAmountDollars = (housePrice * this.loanDetails.loanOptionSecond.downPaymentAmount) / 100
      } else {
        this.loanDetails.loanOptionThird.downPaymentAmountDollars = (housePrice * this.loanDetails.loanOptionThird.downPaymentAmount) / 100
      }
    },
    changePrice() {
      let housePrice = this.editor.houseDetails.property.price;
      housePrice = housePrice.replaceAll(',', '').replaceAll('.00', '').replaceAll(',', '')
      housePrice = parseInt(housePrice)

      this.loanDetails.loanOptionFirst.downPaymentAmountDollars = (housePrice * this.loanDetails.loanOptionFirst.downPaymentAmount) / 100
      this.loanDetails.loanOptionSecond.downPaymentAmountDollars = (housePrice * this.loanDetails.loanOptionSecond.downPaymentAmount) / 100
      this.loanDetails.loanOptionThird.downPaymentAmountDollars = (housePrice * this.loanDetails.loanOptionThird.downPaymentAmount) / 100
    },
    handleInput() {
      if (this.editor.houseDetails.property.desc.length >= this.maxInputLength) {
        this.editor.houseDetails.property.desc = this.editor.houseDetails.property.desc.slice(0, this.maxInputLength);
      }
    }
  },
  computed: {
    isDefaultDisclaimer() {
      return this.disclaimer === this.defaultDisclaimer
    }
  },
  beforeMount() {
    this.getData()
  }
}
</script>

<style lang="scss" scoped>

.page-header {

  @media screen and (max-width: 568px) {
    flex-direction: column;
    justify-content: start !important;
  }

  button {

    &.btn-outline-dark {
      min-width: 130px;
      border: 1px solid rgba(0, 0, 0, .6);
      border-radius: 8px;
      transition: all .3s;

      &:hover {
        background: #efefef;
      }
    }

    &.btn-primary {
      min-width: 150px;
      margin-left: 12px;
      background-color: #065AE4;
      font-size: 12px;
      font-weight: 500;
      letter-spacing: 0;
      line-height: 14px;
      transition: all .3s;

      &:hover {
        background-color: #0555d9;
      }
    }
  }
}

.page-content {

  .page-section {
    margin-right: 160px;

    @media (max-width: 568px) {
      margin-right: 0;
    }

    &-title {
      color: #000000;
      font-size: 18px;
      font-weight: 600;
      letter-spacing: 0;
      line-height: 21px;
      margin: 13px 0 18px 0;
    }

    &-table {
      display: table;
      border-collapse: separate;
      border-spacing: 5px;
      width: 101%;

      &-row {
        display: table-row;
      }

      &-body {
        display: table-row-group;
      }

      &-cell {
        display: table-cell;
        width: 20%;

        &:first-child {
          width: 30%;
        }

        &.table-head {
          padding: 3px 9px;
          border-radius: 4px;
          background-color: #000000;
          color: #FFFFFF;
          font-size: 16px;
          font-weight: 600;
          letter-spacing: 0;
          line-height: 24px;
          margin-right: 5px;
        }

        &.table-row-head {
          color: #000000;
          font-size: 14px;
          letter-spacing: 0;
          line-height: 24px;
          border-radius: 4px;
          background-color: rgba(0, 0, 0, .1);
          padding: 2px 18px;

          &.table-responsive-tab {
            background-color: rgba(0, 0, 0, .2);
          }

          b {
            color: #000000;
            font-size: 16px;
            font-weight: 600;
            letter-spacing: 0;
            line-height: 24px;
          }
        }
      }
    }

    .house-photo {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      border: 1px dashed rgba(0, 0, 0, 0.5);
      border-radius: 8px;
      background-color: #F6F6F6;
      transition: all .3s;
      cursor: pointer;
      z-index: 1;

      &:hover {
        border: 1px dashed #0078FF;
        border-radius: 8px;
        background-color: rgba(0, 120, 255, 0.1);

        span {
          color: #0078FF;
        }
      }

      &.main-photo {
        height: 100%;
      }

      &.optional-photo {
        height: 31.8%;

        span {
          padding: 0 20px;
        }

        img {
          &.remove-photo {
            position: absolute;
            top: 3px;
            right: 3px;
          }
        }
      }

      span {
        color: rgba(0, 0, 0, .7);
        font-size: 12px;
        letter-spacing: 0;
        line-height: 20px;
        text-align: center;
        transition: all .3s;
      }

      img {
        width: 100%;
        height: 100%;
        border-radius: 8px;

        &.remove-photo {
          position: absolute;
          top: 6px;
          right: 6px;
          width: 24px;
          height: 24px;
          z-index: 999;
        }
      }
    }

    .form-group {

      // Hide Arrows for type number
      input[type=number] {
        -moz-appearance: textfield !important;
      }

      input::-webkit-outer-spin-button,
      input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }

      input {
        max-height: 30px;
        min-height: 30px;
        height: 30px;

        &:disabled {
          border: 1px solid #B4B4B4;
          background-color: #E5E5E5;
        }
      }

      textarea {
        min-height: 65px;
      }

      input,
      textarea {
        color: #000000;
        font-size: 14px;
        letter-spacing: 0;
        line-height: 24px;
        border-radius: 4px;
        border: 1px solid #B4B4B4;
        cursor: pointer;
      }

      label {
        color: rgba(0, 0, 0, .7);
        font-size: 11px;
        letter-spacing: 0;
        line-height: 20px;
        margin-bottom: 3px;
      }

      .input-with-icon {

        span {

          &.icon {
            top: 3px;
            color: rgba(0, 0, 0, .7);
            font-size: 14px;
            letter-spacing: 0;
            line-height: 24px;
          }

        }
      }
    }

    .form-floating {

      .input-with-icon {
        position: relative;

        input {
          padding-left: 25px;
        }

        label {
          position: absolute;
          top: 5px;
        }
      }

      label {
        top: 3px;
        padding: 0;
        left: unset;
        right: 9px;
        margin: 0;
        color: rgba(0, 0, 0, .7);
        font-size: 12px;
        letter-spacing: 0;
        line-height: 20px;
        text-align: right;
        transform: none;
        opacity: 1;

        &:focus,
        &:hover {
          transform: none;
          opacity: 1;
        }
      }

      input {
        padding: 0 0 0 10px;
      }
    }

    .double-input {
      padding: 2px 0;
      border: 1px solid #B4B4B4;
      border-radius: 4px;

      input {
        border: none;
        max-height: 24px;
        min-height: 24px;
        height: 24px;
      }

      label {
        top: 2px;
      }

      .form-group {

        .input-with-icon {

          .icon {
            top: 0;
          }
        }

        &:last-child {

          input {
            border: none;
            border-top-left-radius: 0;
            border-bottom-left-radius: 0;
            border-left: 1px solid #B4B4B4;
          }
        }
      }
    }

    .info-block {
      padding: 18px;
      border: 1px solid #D4D4D4;
      border-radius: 4px;

      &-logo,
      &-avatar {
        display: flex;
        align-items: center;
        border: 1px dashed rgba(0, 0, 0, 0.5);
        background-color: #F6F6F6;
        transition: all .3s;
        cursor: pointer;

        span {
          color: rgba(0, 0, 0, .7);
          font-size: 12px;
          letter-spacing: 0;
          line-height: 20px;
          text-align: center;
          transition: all .3s;
        }

        &:hover {
          border: 1px dashed #0078FF;
          background-color: rgba(0, 120, 255, 0.1);

          span {
            color: #0078FF;
          }
        }
      }

      &-avatar {
        position: relative;
        justify-content: center;
        max-width: 120px;
        width: 100%;
        height: 120px;
        border-radius: 50%;

        img {
          width: 100%;
          height: 100%;
          border-radius: 50%;
          object-fit: cover;

          &.remove-photo {
            position: absolute;
            top: 10px;
            right: 5px;
            width: 24px;
            height: 24px;
          }
        }
      }

      &-logo {
        justify-content: space-between;
        border-radius: 8px;
        padding: 6px 12px;
        height: 56px;
      }

      // For Multiselect Component custom styles
      &::v-deep .multiselect {
        width: auto;
        min-height: 30px;
        height: 30px;

        .multiselect__tags {
          background-color: #F3F3F4;
          border: 1px solid #B4B4B4;
          padding-top: 5px;
          padding-left: 10px;
        }

        .multiselect__single {
          background-color: #FFFFFF;
          color: #000000;
          font-size: 14px;
          letter-spacing: 0;
        }

        input {
          background-color: #F3F3F4;
        }

        &.multiselect--active {
          .multiselect__tags {
            transition: all .3s;
            border: 1px solid rgba(38, 111, 229, 0.5);
            box-shadow: 0 0 0 4px rgba(38, 111, 229, 0.1);
          }

          .multiselect__select {
            &:before {
              top: 40%;
              position: relative;

            }
          }
        }

        &__tags {
          padding: 0 40px 0 15px;
          transition: all .3s;
          min-height: 30px;
          border-radius: 4px;
          background-color: white !important;

          &:hover {
            border: 1px solid rgba(38, 111, 229, 0.2);
            box-shadow: 0 0 0 4px rgba(38, 111, 229, 0.1);
          }
        }

        &__select {
          &:before {
            top: 5%;
            position: relative;
            right: 0;
            color: #999;
            border: solid rgba(0, 0, 0, 0.46);
            margin-top: 4px;
            border-width: 0px 1px 1px 0;
            display: inline-block;
            padding: 3px;
            content: "";
            transform: rotate(44deg);
          }
        }

        &__single {
          color: rgba(0, 0, 0, 0.46);
          margin-bottom: 0;
          @media screen and (max-width: 568px) {
            font-size: 9px;
          }
        }

        &__option {
          padding: 10px 12px;
          min-height: 30px;
          transition: all .3s;
          line-height: 20px;
          font-size: 14px;
          color: #000;
          @media screen and (max-width: 568px) {
            font-size: 9px;
          }

          &--highlight {
            background: #f3f3f3;
            line-height: 20px;
            font-size: 14px;
            color: #000;
            @media screen and (max-width: 568px) {
              font-size: 9px;
            }
          }
        }

        &__placeholder {
          padding: 0;
          margin-bottom: 5px;
        }
      }

      .form-control {
        input {
          border: 1px solid #B4B4B4;
        }
      }
    }
  }
}

// Styles for Vue Photo Crop
::v-deep {
  .vue-image-crop-upload {
    .vicp-wrap {
      border-radius: 8px;
      padding: 35px;

      .vicp-close {
        right: 15px;
        top: 15px;

        .vicp-icon4 {
          &:before,
          &:after {
            background: lightgrey;
            box-shadow: none;
          }
        }
      }

      .vicp-step1 {
        a {
          display: flex;
          align-items: center;
          justify-content: center;
          max-height: 36px;
          height: 36px;
          border-radius: 8px;
          opacity: 0.46;
          color: #000000;
          font-size: 12px;
          letter-spacing: 0;
          line-height: 14px;
          transition: all .3s;

          &:hover {
            opacity: 1;
          }
        }
      }

      .vicp-step2 {
        a {
          display: flex;
          align-items: center;
          justify-content: center;
          max-height: 36px;
          height: 36px;
          border-radius: 8px;
          opacity: 0.46;
          color: #000000;
          font-size: 12px;
          letter-spacing: 0;
          line-height: 14px;
          transition: all .3s;

          &:hover {
            opacity: 1;
          }

          &.vicp-operate-btn {
            padding: 0 20px;
            background-color: #28A745;
            color: #FFFFFF;
            font-size: 12px;
            font-weight: 500;
            letter-spacing: 0;
            opacity: 1;
            line-height: 22px;
            border-radius: 8px;
            text-decoration: none;
            @media screen and (max-width: 568px) {
              width: 120px;
              font-size: 10px;
            }

            &:hover {
              background-color: #1d9b3c;
              color: #FFFFFF;
            }
          }
        }

        .vicp-crop-right {
          width: 50%;
        }

        .vicp-preview-item {
          &:first-child {
            display: none;
          }
        }

        .vicp-preview-item-circle {
          img {
            border: 1px dashed rgba(0, 0, 0, .2);
          }
        }
      }

      .vicp-range {
        display: flex;
        align-items: center;

        input {
          border: none;
        }
      }
    }

    .vicp-operate {
      right: 30px;
    }

    .vicp-upload {
      width: 95%;
      margin: 0 auto;
    }

    .vicp-drop-area {
      width: 95%;
      margin: 20px auto;
    }

    .vicp-step2 .vicp-crop .vicp-crop-right .vicp-preview .vicp-preview-item.vicp-preview-item-circle {
      img {
        border: 1px dashed rgba(0, 0, 0, .2);
      }

    }
  }
}
</style>
