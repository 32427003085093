<template>
  <div class="modal modal-center">
    <div class="modal-bg">
      <div class="modal-block">
        <div class="modal-body">
          <button class="btn btn-control close-btn ml-auto" @click="$emit('crop-upload-success')">
            <img src="@/assets/icons/icon-close.png" alt="">
          </button>
          <div class="modal-body-container border-bottom-0" v-if="ui.uploadMode">
            <div class="cursor-pointer" @drag.prevent="activeCropMode">
              <div class="scanned-doc position-relative text-center">
                <input type="file" ref="file" class="position-absolute" @change="activeCropMode" @drag.prevent="activeCropMode">
                <div class="scanned-doc-text">
                  Click of drag the file here to upload
                </div>
              </div>
            </div>
          </div>
          <div class="modal-body-container border-bottom-0" v-if="ui.cropMode">
            <div class="row">
              <div class="col-md-5">
                <vue-cropper
                  ref="cropper"
                  :src="formData.image"
                  :aspect-ratio="1/1"
                  alt="Source Image"
                >
                </vue-cropper>
              </div>
              <div class="col-md-7">
                <img :src="formData.image" class="preview-img">
              </div>
            </div>
            <div class="row">
              <div class="col-md-5">
                <button class="btn btn-primary btn-block mt-3 w-100" @click="cropImage">Crop</button>
              </div>
              <div class="col-md-7 text-right">
                <button type="button" class="btn btn-secondary mt-3" @click="goBack">Back</button>
                <button type="button" class="btn btn-success mx-3 mt-3 w-25" @click="uploadImage">Save</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import VueCropper from 'vue-cropperjs';

export default {
  name: 'CircleCropper',
  components: { VueCropper },
  props: {
    url: String,
    field: String
  },
  data() {
    return {
      ui: {
        uploadMode: true,
        cropMode: false
      },
      formData: {
        image: ''
      }
    }
  },
  methods: {
    activeCropMode() {
      let formData = new FormData()
      formData.append(this.field, this.$refs.file.files[0])
      this.$http.post(this.url, formData).then((res) => {
        this.formData.image = this.backendUrl(res.data.fileName)
        this.ui.uploadMode = false
        this.ui.cropMode = true
      })
    },
    goBack() {
      this.formData.image = ''
      this.ui.cropMode = false
      this.ui.uploadMode = true
    },
    cropImage() {
      this.formData.image = this.$refs.cropper.getCroppedCanvas().toDataURL()
      this.$emit('crop-success', this.$refs.cropper.getCroppedCanvas().toDataURL())
    },
    uploadImage() {
      this.getRoundedCanvas(this.$refs.cropper.getCroppedCanvas()).toBlob((blob) => {
        let formData = new FormData()
        formData.append(this.field, blob)
        this.$http.post(this.url, formData).then(() => {
          this.$emit('crop-success', this.$refs.cropper.getCroppedCanvas().toDataURL())
          this.$emit('crop-upload-success')
        })
      })
    },
    getRoundedCanvas(sourceCanvas) {
      var canvas = document.createElement('canvas');
      var context = canvas.getContext('2d');
      var width = sourceCanvas.width;
      var height = sourceCanvas.height;

      canvas.width = width;
      canvas.height = height;
      context.imageSmoothingEnabled = true;
      context.drawImage(sourceCanvas, 0, 0, width, height);
      context.globalCompositeOperation = 'destination-in';
      context.beginPath();
      context.arc(width / 2, height / 2, Math.min(width, height) / 2, 0, 2 * Math.PI, true);
      context.fill();
      return canvas;
    }
  }
}
</script>

<style lang="scss" scoped>
.modal-center {
  .modal-block {
    width: 600px;
    height: fit-content;
    border-radius: 8px;
    margin: 100px auto;

    @media screen and (max-width: 568px) {
      margin: 0 auto;
    }

    .modal-body {
      padding: 30px;
      @media screen and (max-width: 568px) {
        height: 100vh;
        overflow: scroll;
      }
      .scanned-doc {
        display: flex;
        border: 0;
        height: 170px;
        background-color: rgba(0,0,0,.03);
        text-align: center;
        border: 1px dashed rgba(0,0,0,.08);

        &-text {
          display: flex !important;
          align-items: center;
          justify-content: center;
          width: 100%;
          font-size: 14px;
        }

        input {
          cursor: pointer;
          margin: 0;
          padding: 0;
          max-width: 510px;
          min-height: 140px;
        }
      }

      .preview-img {
        height: 170px;
        border-radius: 50%;
        width: 170px;
        margin-left: 1rem;
      }
    }
    .ml-auto {
      margin-left: auto !important;
    }
    .text-right {
      text-align: right !important;
    }
  }
}

::v-deep {
  .cropper-view-box {
    border-radius: 50%;
  }
  .cropper-face {
    background-color: inherit !important;
  }
}
</style>
